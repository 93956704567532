import cache from '@/utils/cache';
import router from '@/router';

export default {
    currentLang: null,
    cachedUrl: null,
    router: null,

    saveCachedUrl(){
        if(this.cachedUrl== null)
        {
            this.cachedUrl = router.currentRoute.value.fullPath.replace("/"+this.currentLang+"/","/");
            // console.log('401 登录超时，临时记录地址：'+this.cachedUrl);
        }
    },

    getCurrentLang() {
        if(this.currentLang)
        {
            return this.currentLang;
        }
        else
        {
            return cache.getLang() || 'en' ;
        }
    },
}

