<template>
    <base-dialog :data="props.data" width="430px" :title="$t('message.change_pwd_title')" @cancel="closeDialog">
        <div style=" margin: 30px;">
            <div>
                <a-form :model="pageData.formData" name="basic" autocomplete="off" :label-col="{ span: isCN() ? 6 : 10 }"
                    labelAlign="left" @finish="onFinish" @finishFailed="onFinishFailed" :colon="false">
                    <a-form-item :label="$t('message.change_pwd_old_pwd')" name="oldPwd"
                        :rules="[{ required: true, message: $t('message.change_pwd_old_pwd_empty') }]">
                        <a-input-password v-model:value="pageData.formData.oldPwd" />
                    </a-form-item>
                    <div style="height: 10px;"></div>

                    <a-form-item :label="$t('message.forgot_pwd_newPwd')" name="newPwd"
                        :rules="[{ required: true, message: $t('message.user_new_pwd_empty') }, { validator: validatePwdFormat }]">
                        <a-input-password v-model:value="pageData.formData.newPwd" />
                    </a-form-item>

                    <div style="height: 10px;"></div>
                    <a-form-item :label="$t('message.forgot_pwd_confirmPwd')" name="confirmPwd" :rules="[{ required: true, message: $t('message.user_confirm_pwd_empty') }, {
                        validator: handleConfirmPassword
                    }]">
                        <a-input-password v-model:value="pageData.formData.confirmPwd" />
                    </a-form-item>
                    <div style="height: 10px;"></div>
                    <a-row>
                        <a-col :span="24" style="text-align: center">
                            <a-button type="primary" html-type="submit" shape="round" :loading="loading" block
                                style="height: 48px;">
                                {{ $t('message.change_pwd_button_ok') }}
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </div>
    </base-dialog>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import { isCN } from '@/utils/tool';
import { useI18n } from 'vue-i18n';
import { successMessage, warningMessage } from '@/utils/messageUtils';
import { changePwd } from '@/api/index';
import BaseDialog from "@/components/common/BaseDialog.vue";

const props = defineProps({
    data :{
        show:false,
    }
});

const { t } = useI18n();

let loading = ref(false);

const pageData = reactive({
    formData: {},
});

async function onFinish(values) {
    var data = { "oldPwd": pageData.formData.oldPwd, "newPwd": pageData.formData.newPwd };

    loading.value = true;
    var result = await changePwd(data);
    loading.value = false;
    if (result) {
        successMessage(t('message.change_pwd_success'));
        closeDialog();
    }
};

///密码一直判断
function handleConfirmPassword(rule, value, callback) {

    if (value && value !== pageData.formData.newPwd) {
        return Promise.reject(t('message.user_pwd2_empty'));
    }

    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
}

function validatePwdFormat(rule, password, callback) {
    // var specialWordValus = "!@#%^&*\$".codeUnits;
    if (!password) {
        return Promise.reject();
    }

    if (password.length < 8 || password.length > 16 || password.search(" ") > -1) {
        return Promise.reject(t('message.password_format_wrong'));
    } else {
        //数字
        let countNumber = 0;
        let countWord = 0;
        // int countSpecialWord = 0;
        let chars = password.split("");
        for (var i in chars) {
            let value = chars[i];
            if (value >= '0' && value <= '9') {
                countNumber++;
            } else if ((value >= 'a' && value <= 'z') ||
                (value >= 'A' && value <= 'Z')) {
                countWord++;
            }
        }
        if (countNumber > 0 && countWord > 0) {
        }
        else {
            return Promise.reject(t('message.password_format_wrong'));
        }
    }

    callback();

}

function onFinishFailed(errorInfo) {
    // console.log('Failed:', errorInfo);
};

function closeDialog() {
    //子组件中调用方法
    props.data.show = false;
}

onMounted(() => {

});

</script>

<style scoped lang="less">
.carrierName {
    overflow: hidden;
    margin-left: 10px;
    font-size: 16px;
    width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

:deep(.ant-form-item-explain-error) {
    color: #f05b5b;
    font-size: 12px;
}
</style>