import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {i18n} from './locals/index';
import "dayjs/locale/zh-cn"; // 日期的周和月 汉化不完全的，解决办法。导入
import dayjs from 'dayjs';
import UpdateLocale from 'dayjs/plugin/updateLocale';

import Antd from 'ant-design-vue';
// import * as echarts from 'echarts';
import echarts from './config/uiPluginConfig';

import { ElProgress } from 'element-plus';
import 'element-plus/dist/index.css';

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//全局组件
import Header from "@/components/menu/Header.vue";

// 默认主题用下面的
// import 'ant-design-vue/dist/antd.css';

// 自定义主题用下面的
import 'ant-design-vue/dist/antd.less';
import "@/assets/aitrack_iconfont/iconfont.css"; // 使用自定义字体图标库
import "@/assets/icon_font/iconfont.css"; // 字体小图标
import "@/assets/aitrack.css";

//修改本地周日为一周第一天
dayjs.extend(UpdateLocale);
dayjs.updateLocale('zh-cn', {weekStart: 0});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyATke-X2A4wcseQzhHV9Kf4iAYaI-6GM0U",
//   authDomain: "aitrack-2023.firebaseapp.com",
//   projectId: "aitrack-2023",
//   storageBucket: "aitrack-2023.appspot.com",
//   messagingSenderId: "456357405503",
//   appId: "1:456357405503:web:17d954523b3743b196e98a",
//   measurementId: "G-Q0FMQ6T5FY"
// };
  
// // Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);


const app = createApp(App);
app.use(Antd).use(router).use(i18n);
app.component(ElProgress.name, ElProgress);

app.config.globalProperties.$echarts = echarts;
//全局组件
app.component('Header',Header);
app.mount('#app');

