// 时间处理工具类
import dayjs from 'dayjs';

export default {
    yyyyMMdd: 'YYYY-MM-DD',
    yyyyMM: 'YYYY-MM',
    yyyyMMDDHHMMSS: 'YYYY-MM-DD HH:mm:ss',

    /**
     * 获取传入日期所在周的第一天
     * @param {*} date 
     */
    getFirstWeekDate(today) {
        let todayWeek = today.day();
        let startTime = today.subtract(todayWeek, 'day').format(this.yyyyMMdd);

        return dayjs(startTime);
    },

    /**
     * 获取传入日期所在周的最后一天
     * @param {*} date 
     */
    getLastWeekDate(today) {
        let endTime = this.getFirstWeekDate(today).add(6, 'day').format(this.yyyyMMdd);
        return dayjs(endTime);
    },

    /**
     * 天数计算
     * @param {*} date 
     * @param {*} day 
     * @returns 
     */
    getUtcByAddDay(date,day) {
        let dateTime = dayjs(date).add(day, 'day').format(this.yyyyMMdd);
        return this.getUtcStartByUtc(dayjs(dateTime).valueOf());
    },

    /**
     * 天数差
     */
    getDiffDays(start, end) {
        return end.diff(start.format(this.yyyyMMdd), 'day');
    },

    // 格式化日期时间(yyyy-MM-DD HH:mm:ss)
    getFormatDateTimeByUtc(utc) {
        if (utc) {
            return dayjs(utc).format(this.yyyyMMDDHHMMSS);
        }
        return '';
    },

    // 格式化日期时间(yyyy-MM-DD HH:mm:ss),展示UTC时间
    getDateTimeByUtc(utc) {
        if (utc) {
            let minute = new Date().getTimezoneOffset() || 0
            return dayjs(utc).add(minute, 'minute').format(this.yyyyMMDDHHMMSS);
        }
        return '';
    },

    /**
     * 格式化日期(yyyy-MM-DD)
     * @param {*} utc 
     * @returns 
     */
    getFormatDateByUtc(utc) {
        if (utc) {
            return dayjs(utc).format(this.yyyyMMdd);
        }
        return '';
    },

    /**
     * 格式化月份(yyyy-MM)
     * @param {*} utc 
     * @returns 
     */
    getFormatMonthByUtc(utc) {
        if (utc) {
            return dayjs(utc).format(this.yyyyMM);
        }
        return '';
    },

    /**
     * UTC 取当天凌晨UTC
     * @param {*} utc 
     * @returns 
     */
    getUtcStartByUtc(utc) {
        if (utc) {
            let date = dayjs(utc).format(this.yyyyMMdd) + " 00:00:00";
            return new Date(date).getTime();
        }
        return utc;
    },

    /**
     * UTC 取当天最后一毫秒UTC
     * @param {*} utc 
     * @returns 
     */
    getUtcEndByUtc(utc) {
        if (utc) {
            let date = dayjs(utc).format(this.yyyyMMdd) + " 00:00:00";
            return new Date(date).getTime() + 86400000 - 1; // +1天-1毫秒
        }
        return utc;
    }
}
