import cache from '@/utils/cache';
import {warningMessage} from '@/utils/messageUtils';
import router from '@/router';
import enUS from 'ant-design-vue/es/locale/en_US';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import frFR from 'ant-design-vue/es/locale/fr_FR';
import esES from 'ant-design-vue/es/locale/es_ES';
import {i18nText} from '@/locals/index';
import urlConfig from '@/config/urlConfig';
import webConfig from '@/config/wenConfig';
import dateTimeUtils from '@/utils/dateTimeUtils';
import { Modal } from 'ant-design-vue'

// 上传的时候，删除无用的key
export const deleteEmptyKey = (object) => {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const element = object[key];
            if (element === null || element === undefined || element === '') {
                delete object[key];
            }
        }
    }
}

export const getRequestParams = (searchParams, pagination) => {
    let param = {
        ...searchParams,
        page: pagination.page,
        size: pagination.size
    };
    this.deleteEmptyKey(param);
    return param;
}

// 格式化日期:毫秒格式化为yyyy-MM-DD HH:mm:ss
// export const formatDateTime = (utc) => {
//     return dateTimeUtils.getFormatDateTimeByUtc(utc);
// }

// // 格式化日期
// export const formatDate = (utc) => {
//     return dateTimeUtils.getFormatDateByUtc(utc);
// }

// export const formatMonth = (utc) => {
//     return dateTimeUtils.getFormatMonthByUtc(utc);
// }

// // UTC 去当天凌晨UTC
// export const utcStart = (utc) => {
//     return dateTimeUtils.getUtcStartByUtc(utc);
// }

// // UTC 去当天最后一毫秒UTC
// export const utcEnd = (utc) => {
//     return dateTimeUtils.getUtcEndByUtc(utc);
// }

// 一级状态Map
export const getEventStatusMap = (lstEventStatus) => {
    var idToEventStatus = new Map();
    lstEventStatus.forEach(element => {
        idToEventStatus.set(element.id, element);
    });
    return idToEventStatus;
}

// 路径增加语言
export const toUrl = (url) => {
    return '/' + webConfig.getCurrentLang() + url;
}


// 内部页面路径跳转
export const routerToUrl = (url, query) => {
    router.push({path: toUrl(url), query});
}


// 跳转到Flutter地址
// export const toAdminUrl = (url) => {
//     let flutterPath = "/admin";
//     let defaultPath = "/#";

//     let toURL = window.location.origin + flutterPath + defaultPath + '/' + webConfig.getCurrentLang() + url;

//     var aElement = document.createElement("a")
//     // (2)给元素添加必要的标示信息
//     aElement.href = toURL.replace(':8889', '');
//     aElement.target = "_self";

//     // 添加到document.body
//     document.body.appendChild(aElement);
//     aElement.click();
//     document.body.removeChild(aElement);

// }

// 跳转到Flutter地址
export const openTab = (url) => {
    if (url.startsWith('http') == false) {
        url = "http://" + url;
    }
    var aElement = document.createElement("a")
    // (2)给元素添加必要的标示信息
    aElement.href = url;
    aElement.target = "_blank";

    // 添加到document.body
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);

}

// 当前语言是否为中文
export const isCN = () => {
    return webConfig.getCurrentLang() == 'zh';
}

// 是否为手机设备（根据分辨率判断）
export const isPhone = () => {
    let width = document.body.clientWidth;
    let height = document.body.clientHeight;

    // console.log(width + ' * ' + height);

    if (width <= 768) {
        return true;
    }

    return false;
}

export const isEmail = (email) => {
    const reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
    if (!email || ! reg.test(email)) {
        return false;
    }
    return true;
}

export const checkEmail = (a, value) => {
    if (value && isEmail(value) == false) {
      return Promise.reject(new Error(i18nText('message.user_email_validate')));
    }
    return Promise.resolve();
  };


export const checkPwd = (password) => { // var specialWordValus = "!@#%^&*\$".codeUnits;
    if (password.length < 8 || password.length > 16 || password.indexOf(" ") != -1) {
        return false;
    } else { // 数字
        let countNumber = 0;
        let countWord = 0;
        let nums = "0123456789";
        let chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        // int countSpecialWord = 0;
        for (var value of password) {
            if (nums.indexOf(value) != -1) {
                countNumber++;
            } else if (chars.indexOf(value) != -1) {
                countWord++;
            }
        }

        return countNumber > 0 && countWord > 0;
    }
}

// 当前是否已登录
export const getLogined = () => { // 登录状态数据
    var userName = cache.getUserName();
    if (userName) {
        return true;
    }

    return false;
}

// 登录成功
export const loginSuccess = (email, userName) => {
    cache.saveEmail(email);
    cache.saveUserName(userName);
}

// 注销登录
export const clearLoginData = () => { // 登录状态数据
    cache.logout();
    window.sessionStorage["autoGoogleLogin"] = "0";
}

// 校验服务器返回状态（code=200 返回true, 否则返回false, 传入错误码对应错误，否则原样显示错误）
export const checkResponse = (response, codeToMessage) => { // 登录状态数据
    if (null == response) {
        return false
    }

    if (response.code === 200) {
        return true;
    } else if (codeToMessage.has(response.code)) {
        warningMessage(codeToMessage.get(response.code));
    } else {
        warningMessage(response.message);
    }

    return false;
}


export const getBaseUrl = () => {
    return urlConfig.getBaseUrl();
}

///网站地址
export const getWebsitUrl = () => {
    return urlConfig.getWebsitUrl();
}

// /航班包裹信息
export const initParcelDetail = (element) => {
    let parcelInfo = "";
    if (element) {
        let hasWeight = false;
        if (element.weight && element.weightUnit) {
            parcelInfo += element.weight + element.weightUnit;
            hasWeight = true;
        }

        if (null != element.partNum) {
            if (hasWeight) {
                parcelInfo += " | ";
            }
            parcelInfo += element.partNum.toString() + i18nText('message.parcel_unit');
        }
        element.parcelDetail = parcelInfo;
    }

    return parcelInfo;

}

// Ant design 当前设置语言
export const getAntUILocal = () => {
    switch (webConfig.getCurrentLang()) {
        case "zh":
            return zhCN;
        case "fr":
            return frFR;
        case "es":
            return esES;
        default:
            return enUS;
    }

}

// 自动计算，窗口的最多显示数据条数
export const getAutoPageSize = (rowHeight, removeHeight, minCount) => {
    let height = window.innerHeight - removeHeight;
    let size = height / rowHeight > minCount ? height / rowHeight : minCount;
    return Math.floor(size);
}


export const downloadBlob = (res) => {
    const a = document.createElement('a')
    // 构造一个blob对象来处理数据
    // const blob =  Blob[res.data]
    var content_disposition = res.headers['content-disposition'] || res.headers['Content-Disposition'];
    if (content_disposition) { // 拿到用户上传的文件名
        let fileName = content_disposition;
        fileName = fileName.split('filename=')[1];
        fileName = decodeURI(decodeURI(fileName));
        // console.log(fileName);
        // URL.createObjectURL()会产生一个url字符串，可以像使用普通 URL 那样使用它，比如用在 img.src 上
        // console.log(res.code);

        let binaryData = [res.data];
        // binaryData.push();

        a.href = window.webkitURL.createObjectURL(new Blob(binaryData));
        // a标签里有download属性可以自定义文件名
        a.setAttribute('download', fileName.replace(new RegExp('"', "g"), "")) // 不过滤会出现 下载的文件名 _aa.xlsx_
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
    }
}

export const downloadByFormPost = (url) => {

    var iframeElement = document.createElement("iframe");
    iframeElement.name = 'download';

    iframeElement.style.display = "none"; // 防止影响页面
    iframeElement.style.height = 0; // 防止影响页面
    document.body.appendChild(iframeElement);


    var formElement = document.createElement("form");
    formElement.method = "post";
    formElement.action = url;
    formElement.target = "download";
    document.body.appendChild(formElement);

    formElement.submit({timeout: 36000});

}

export const biDownloadExcel = (url) => {

    // console.log(url);
    var iframeElement = document.createElement("iframe");
    iframeElement.name = 'atDownload';

    // iframeElement.src =  url.split('?')[0];
    iframeElement.style.display = "none"; // 防止影响页面
    iframeElement.style.height = 0; // 防止影响页面
    document.body.appendChild(iframeElement);


    var formElement = document.createElement("form");
    formElement.method = "post";
    formElement.action = url.split('?')[0].replace('#', '?');
    formElement.target = "atDownload";
    document.body.appendChild(formElement);

    var json = url.split('?')[1];
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "json");
    input.setAttribute("value", json);
    formElement.appendChild(input);

    formElement.submit({ timeout: 36000 });

    setTimeout(() => {
      document.body.removeChild(iframeElement);
      document.body.removeChild(formElement);
    }, 32000);

}

// 保留4位小数，舍弃后面部分
export const floatToFiexed4 = (v1, v2) => {
    return(parseInt(v1 * 10000 / v2) / 10000).toFixed(4);
}

// 千分位格式化
export const formatThousands = (num) => {

    if (!num && num != 0) {
        return "";
    }


    return(num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
}

//下拉框-搜索
export const filterOption =(input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

//运输商-搜索
export const carrierFilterOption =(input, option) => {
    return option.fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const  hasHtmlTags = (str) => {
    const regex = /<\s*(html|head|body|meta|link|title|style|script|h1|h2|h3|h4|h5|h6|p|b|br|hr|ul|ol|li|img|a|form|input|label|select|option|textarea)\s*[^>]*>/i;
  return regex.test(str);
  }

/**
 * 验证订阅额度是否充足，否则弹出提示框
 * @param {*} needCount 
 * @param {*} balanceTooltipDialogData 
 */
export const validateBalance = async (needCount, balanceTooltipDialogData, limitData)  => {
    
    if(needCount > limitData.v)
    {
        //提示-额度不足
        balanceTooltipDialogData.type = "balance";
        balanceTooltipDialogData.show = true;
        return false;
    }
    else if(new Date().getTime() > limitData.expiryTime){
        //提示-有效期过了
        balanceTooltipDialogData.type = "time";
        balanceTooltipDialogData.show = true;
        return false;
    }
    return true;
};

export const validateBalanceByCode = async (balanceTooltipDialogData, code)  => {
    
    if(code == 108)
    {
        //提示-额度不足
        balanceTooltipDialogData.type = "balance";
        balanceTooltipDialogData.show = true;
        return false;
    }
    else if(code == 109){
        //提示-有效期过了
        balanceTooltipDialogData.type = "time";
        balanceTooltipDialogData.show = true;
        return false;
    }
    return true;
};

//密码-格式
export const  validatePwdFormat = (rule, password, callback) =>{
    // var specialWordValus = "!@#%^&*\$".codeUnits;
    if (!password) {
        return Promise.reject();
    }

    if (password.length < 8 || password.length > 16 || password.search(" ") > -1) {
        return Promise.reject(i18nText('message.password_format_wrong'));
    } else {
        //数字
        let countNumber = 0;
        let countWord = 0;
        // int countSpecialWord = 0;
        let chars = password.split("");
        for (var i in chars) {
            let value = chars[i];
            if (value >= '0' && value <= '9') {
                countNumber++;
            } else if ((value >= 'a' && value <= 'z') ||
                (value >= 'A' && value <= 'Z')) {
                countWord++;
            }
        }
        if (countNumber > 0 && countWord > 0) {
        }
        else {
            return Promise.reject(i18nText('message.password_format_wrong'));
        }
    }

    return Promise.resolve();
}

export const modelPop = (title, content,okBtnText, callback) =>{
    localStorage.setItem('isShowModelPop', true);
    Modal.confirm({
        title: title,
        content: content,
        okText: okBtnText,
        cancelText: i18nText('message.button_cancel_text'),
        onOk () {
            localStorage.setItem('isShowModelPop', false);
            callback()
        },
        onCancel () {
            localStorage.setItem('isShowModelPop', false);
        }
    })
}
