<template>
    <a-modal ref="modalRef" v-model:visible="props.data.show" :style="{top:'80px', width: props.width, minWidth: props.width}" :footer="null" @cancel="closeDialog"
        :maskClosable="false">
        <template v-slot:title>
            <div ref="modalTitleRef" style="margin-right: 25px;cursor: move">
                {{ props.title }}
            </div>
        </template>
        <template v-slot:closeIcon>
            <div style="color: white;">
                <CloseOutlined />
            </div>
        </template>
        <template #modalRender="{ originVNode }">
            <div :style="transformStyle">
            <component :is="originVNode" />
            </div>
        </template>

        <!-- 具体内容 -->
        <div style="overflow-y: auto;" :style="{ maxHeight:maxHeight }">
          <slot />
        </div>
        <slot name="footer" />
    </a-modal>
</template>

<script setup>
    import { CloseOutlined } from '@ant-design/icons-vue';
    import { ref, computed, watch, watchEffect, onMounted } from 'vue';
    import { useDraggable } from '@vueuse/core';

    let maxHeight = ref('100%');
    
    const props = defineProps({
        title:'',
        width:'',
        showFooter: false,
        data: {
            show: false
        },
    });

    const emits = defineEmits(['closeDialog']);
    function closeDialog(){
        emits('closeDialog');
    }

    // 标题行拖动相关配置

    const modalTitleRef = ref(null);
    const {
      x,
      y,
      isDragging,
    } = useDraggable(modalTitleRef);

    const startX = ref(0);
    const startY = ref(0);
    const startedDrag = ref(false);
    const transformX = ref(0);
    const transformY = ref(0);
    const preTransformX = ref(0);
    const preTransformY = ref(0);
    const dragRect = ref({
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    });

    watch(() => props.data.show, (newValue, oldValue) => {
      if (newValue) {
        transformX.value=0;
        transformY.value=0;

        startX.value=0;
        startY.value=0;
        startedDrag.value =false;
        transformX.value=0;
        transformY.value=0;
        preTransformX.value=0;
        preTransformY.value=0;

        dragRect.left=0;
        dragRect.right=0;
        dragRect.top=0;
        dragRect.bottom=0;

      }
    }, { deep: true });

    
    watch([x, y], () => {
      if (!startedDrag.value) {
        startX.value = x.value;
        startY.value = y.value;
        const bodyRect = document.body.getBoundingClientRect();
        const titleRect = modalTitleRef.value.getBoundingClientRect();
        dragRect.value.right = bodyRect.width - titleRect.width;
        dragRect.value.bottom = bodyRect.height - titleRect.height;
        preTransformX.value = transformX.value;
        preTransformY.value = transformY.value;
      }
      startedDrag.value = true;
    });
    watch(isDragging, () => {
      if (!isDragging) {
        startedDrag.value = false;
      }
    });
    watchEffect(() => {
      if (startedDrag.value) {
        transformX.value = preTransformX.value + Math.min(Math.max(dragRect.value.left, x.value), dragRect.value.right) - startX.value;
        transformY.value = preTransformY.value + Math.min(Math.max(dragRect.value.top, y.value), dragRect.value.bottom) - startY.value;
      }
    });
    const transformStyle = computed(() => {
      return {
        transform: `translate(${transformX.value}px, ${transformY.value}px)`,
      };
    });

    onMounted(() => {
      let dialogMaxHeight = document.body.clientHeight - 130;
      if(dialogMaxHeight<300)
      {
        dialogMaxHeight = 300;
      }

      if (props.showFooter) {
        maxHeight.value = (dialogMaxHeight - 54) + "px";
      }
      else {
        maxHeight.value = dialogMaxHeight + "px";
      }

    });

</script>

<style>

</style>