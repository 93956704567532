export default {
	message: {
		title: "Global package tracking",
		search: "Ai track",
		carrier: "Identify carrier automatically",
		carrier_name: "Carrier",
		carrier_description:
			"System will identify carrier according to the tracking number",
		auto_check: "Identify automatically",
		clear_all: "Delete all",
		select_transport_title: "Select carrier",
		all_carrier: "Major global carriers",
		all_airline: "All airlines",
		common_carriers: "Common carriers",
		carrier_page_title: "Track",

		not_found_order_no:
			"No matching package, please check tracking number and carrier",
		order_no_has_shipment: "No tracking result, check flight information",

		network_error: "Network request error",
		order_no_maybe_carriers:
			"The tracking number may belong to one of the following carriers ",
		your_order_no_exists_many_carriers:
			"There are multiple possible carriers for your tracking number ",
		tracking_result_title: "Tracking result",
		air_tracking_result_title: "Flight information",

		copy_link: "Copy link",
		copy_detail: "Copy details",
		more_detail: "More information",
		end_logistics_number: "Last Mile Tracking No.",
		dispatcher: "Carrier",
		dispatcher_website: "Carrier website",
		phone: "Carrier Tel",
		more_detail_btn_tip: "No more information.",

		// menu
		menu_tracking_no_search: "Waybill inquiry",
		menu_history: "Historical waybill",
		menu_log: "Update notes",

		// /Result tab title
		all: "All",
		unknow: "Unknown",

		tracking_list_empty: "No matching record",
		air_list_empty: "No flight data",

		email_code_send_success: "Verification code has been sent",

		// /运输单号校验
		title_input_order_no: "Please enter tracking number(7~50 digits)",
		tracking_no_duplicate: "Duplicate tracking number",
		tracking_no_hint: "Enter tracking number, up to 20 are allowed",

		copy_success: "Copy successfully",

		normalCarriers: "Logistics carrriers",
		airCarriers: "Air carriers",
		try_title: "Try：",
		parcel_unit: "Pieces",

		air_no: "Flight",
		air_start_end_station: "Departure station/arrival Station",
		air_maybe_start_time: "Estimated time of departure",
		air_maybe_end_time: "Estimated time of arrival",
		air_start_time: "Departure time",
		air_end_time: "Arrival time",
		weight_unit: "Weight/Pieces",

		// login
		login_title: "Log in",
		login_register: "Register an account >",
		login_button: "Login",
		login_forget_pwd: "Forgot password",
		login_password: "Password",
		login_email: "Email address",
		remember_me: "Remember password",

		// 忘记密码
		forgot_pwd_title: "Forgot password",
		forgot_pwd_code: "Verification code",
		forgot_pwd_newPwd: "New password",
		forgot_pwd_confirmPwd: "Confirm password",
		forgot_pwd_ok: "Reset password",
		forgot_pwd_login: "Login >",
		forgot_pwd_send_code: "Send",
		forgot_pwd_send_second: " seconds",

		// 修改密码
		change_pwd_title: "Change password",
		change_pwd_old_pwd: "Original password",
		change_pwd_old_pwd_empty: "Original password cannot be empty",
		change_pwd_success: "Change password successfully",
		change_pwd_button_ok: "Confirm",

		// 注册用户
		register_title: "Register an account",
		register_name: "Nickname",
		register_ok: "Register now",
		password_format_wrong:
			"The password must contain 8-16 characters and contains at least numbers and letters, and cannot contain spaces",
		privacy_agreement_tip:'By registering, you agree to',
		privacy_agreement:'《Aitrack Privacy Policies》',
		user_email_empty: "Email address cannot be empty",
		user_email_validate: "Email address format is not correct",
		user_name_empty: "Nickname cannnot be empty",
		user_email_code_empty: "Verification code cannot be empty",
		user_new_pwd_empty: "Password cannot be empty",
		user_confirm_pwd_empty: "Confirm password cannot be empty",
		user_pwd2_empty: "Two passwords are entered inconsistently",

		regist_login: "Register/Login",
		back_home: "Back to home",
		registration_agreement: "注册即表示同意《AiTrack注册协议》",
		synchronization_time: "Synchronised time：",

		register_success: "Register successfully",
		reset_pwd_success: "Reset password successfully",

		// 日期组件
		date_dialog_today: "Today",
		date_dialog_week: "Last week",
		date_dialog_month: "Last month",

		// 请求code错误
		request_101_params_error: "Wrong request parameters",
		request_103_page_params_error: "Wrong pagination query parameters",
		request_104_no_permission: "No access permisson",

		request_portal_102_login_name_exists: "Nickname is already used",
		request_portal_105_email_exists: "Email address is already used",
		request_portal_106_code_exists: "Wrong verification code",
		request_portal_107_login_fail: "Wrong email address or password ",
		request_portal_108_email_not_exists: "Email address is not registered",
		request_portal_109_account_disabled: "Account is disabled",
		request_portal_110_password_error: "Wrong original password",
		request_portal_111_email_exists:
			"The email account already exists, please log in directly",
		request_portal_112_password_format_wrong:
			"The password must contain 8-16 characters and contains \nat least numbers and letters, and cannot contain spaces",

		// 请求code错误-结束

		keyword_search_carriers: "Search carriers using keyword",

		user_logout: "Sign out",

		table_no: "No.",
		table_operate: "Operate",
		table_sort_asc: "",
		table_sort_desc: "",
		table_page_total: "Total",
		table_page_row: "",
		table_no_select: "No row selected",
		table_delete: "Delete",
		table_handle_tracking: "Manual tracking",
		table_handle_stop: "Stop tracking",
		table_delete_rows: "Are you sure you want to delete?",

		// /按钮文字
		button_confirm_text: "Confirm",
		button_ok_text: "OK",
		button_cancel_text: "Cancel",

		// /历史运单表格列名
		history_table_tracking_no: "Tracking number",
		// 'history_table_carriers': 'Carriers',
		history_table_package_status: "Package status",
		history_table_event: "Event",
		history_table_event_time: "Event time",
		history_table_shipping_update_time: "Data update time",
		history_table_update_time: "Latest query time",
		create_time: "Create time",
		history_input_tracking_no_search: "Enter tracking number to inquire",

		// 页面-更新日志
		log_page_title: "Support 100+ cross-border logistics carriers",
		log_page_description:
			"We have supported USPS, ROYALMAIL, DHL, GLS and other carriers, we are simplifying and integrating process, and continue to establish partnerships with more carriers.",

		// 提示
		tooltip_click_copy: "Click to copy",

		home_page_input_search_carrier: "Search carriers",

		// /用户运单查询-批量导入弹框
		user_search_page_dialog_import_batch: "Batch import",

		user_search_page_dialog_file_upload: "Click to upload file",
		user_search_page_dialog_file_format_incorrect:
			"The imported file format is not correct ",
		user_search_page_dialog_step1_click: "1.Click",
		user_search_page_dialog_step1_download_template: " Download template ",
		user_search_page_dialog_step1_end:
			"Please fill in the import list(check carrier code after switching sheet).",
		user_search_page_dialog_step2:
			"2.The duplicate waybill will be tracked together, and the original tracking will be covered with the latest tracking, import up to 1000 waybills at one time.",
		user_search_page_dialog_step3:
			"3.The tracking information will not be updated immediately after the file is imported. Please check the tracking progress in the Historical waybill.",
		user_search_page_dialog_select_file:
			"Please select the file to upload first",
		user_search_page_dialog_upload_success:
			"File is uploaded successfully, you can check the tracking progress in the Historical waybill.",
		user_search_page_dialog_upload_fail: "Import failed",
		user_search_page_dialog_more_then_100:
			"import up to 1000 waybills at one time.",

		query_fail_input_verification: "Please enter verification information",

		// /运单查询页（查询结果页，用户查询页）
		tracking_page_input_post_code: "Please enter destination zip code",
		tracking_page_select_date: "Please select a pickup date for the package",
		tracking_page_input_phone: "Please enter recipient's cellphone number",
		tracking_page_input_dest: "Please enter a destination",

		// /提交建议
		connect_us_title: "Contact us",
		connect_us_samll_title: "Choose your suggestions to aitrack：",
		connect_us_input_title: "Please enter a title name ",
		connect_us_title_name: "Title name",
		connect_us_title_name_length_2:
			"Title name must contain at least 2 characters",
		connect_us_input_suggest: "Please enter your suggestions",
		connect_us_your_suggest: "Your suggestions",
		connect_us_your_suggest_length_10:
			"Your suggestions must contain at least 10 characters",
		connect_us_your_email: "Your email",
		connect_us_your_phone: "Your cellphone number",
		connect_us_your_submit_suggest: "Submit a suggestion",

		connect_us_suggest1:
			"I would like to suggest aitrack to develop a new function",
		connect_us_suggest2: "I want aitrack to add new carriers",
		connect_us_suggest3: "I want to report a bug",
		connect_us_suggest4: "I want to do business with aitrack",
		connect_us_suggest5: "Others",

		research_btn: "requery",

		// 批量导入成功结果展示
		import_excel_result_upload_success: "File is uploaded successfully",
		import_excel_result_success_count: "Number of successful waybills：",
		import_excel_result_fail_count: "Number of failed waybills：",
		import_excel_result_success_rewrite:
			" waybills already exist and be covered",
		import_excel_result_error_format: " (Wrong format )",
		import_excel_result_view_fail_detail: "Check failure details",
		import_excel_result_success_description:
			"you can check the tracking progress in the Historical waybill.",
		import_excel_result_table_column_row_number: "Table row number",
		import_excel_result_table_column_fail_tracking_no: "Failed waybill number",
		import_excel_result_table_column_fail_reason: "Reason for failure",

		// Excel导入错误提示
		import_excel_result_validate_tracking_no: "Tracking number required",
		import_excel_result_validate_carrier_code: "Wrong carrier code",
		import_excel_result_validate_post_code: "Missing destination zip code",
		import_excel_result_validate_date: "Missing delivery date",
		import_excel_result_validate_phone: "Missing recipient cellphone number",
		import_excel_result_validate_dest: "Missing destination",

		import_excel_ok:
			"Received, we are very grateful for your help and support! ",

		// /2022-04-08 后面追加新的翻译
		// /全部
		event_names_all: "All",
		event_names_all_des: "",

		// /未知
		event_names_unknown: "Unknown",
		event_names_unknown_des: "",

		// /未查询到
		event_names_not_found: "Not found",
		event_names_not_found_des:
			"There is no tracking information for the package.",

		// /运输途中
		event_names_in_transit: "In transit",
		event_names_in_transit_des:
			"The carrier has received or picked up the package from the shipper. The goods are on their way. ",

		// /到达待取
		event_names_arrival_for_pickup: "Pick up",
		event_names_arrival_for_pickup_des:
			"The carrier has delivered the package to the designated pickup point.",

		// /投递失败
		event_names_delivery_failed: "Undelivered",
		event_names_delivery_failed_des:
			"The carrier attempted to deliver but failed. Usually the carrier will notify you and try to deliver again. ",

		// /成功签收
		event_names_signed_successfully: "Delivered",
		event_names_signed_successfully_des:
			"The package was successfully signed for.",

		// /可能异常
		event_names_possible_exceptions: "Alert",
		event_names_possible_exceptions_des:
			"Packages held by customs, undelivered, returned to sender, or any other shipping exception. ",

		// /运输过久
		event_names_shipping_too_long: "Expired",
		event_names_shipping_too_long_des:
			"The carrier has not provided logistics information for the package for a long time. ",

		// /2022-04-11 后面追加新的翻译
		utc_0: "World time UTC+0",
		utc_1: "East first district UTC+1",
		utc_2: "East second district UTC+2",
		utc_3: "East third district UTC+3",
		utc_4: "East fourth district UTC+4",
		utc_5: "East fifth district UTC+5",
		utc_5_5: "East fifth district UTC+5.5",
		utc_6: "East sixth district UTC+6",
		utc_7: "East seventh district UTC+7",
		utc_8: "East eigith district UTC+8",
		utc_9: "East ninth district UTC+9",
		utc_10: "East tenth district UTC+10",
		utc_11: "East eleventh district UTC+11",
		utc_12: "East twelfth district UTC+12",
		utc_13: "East thirteenth district UTC+13",
		utc_14: "East fourteenth district UTC+14",
		"utc_-1": "West first district UTC-1",
		"utc_-2": "West second district UTC-2",
		"utc_-3": "West third district UTC-3",
		"utc_-4": "West fourth district UTC-4",
		"utc_-5": "West fifth district UTC-5",
		"utc_-6": "West sixth district UTC-6",
		"utc_-7": "West seventh district UTC-7",
		"utc_-8": "West eigith district UTC-8",
		"utc_-9": "West ninth district UTC-9",
		"utc_-10": "West tenth district UTC-10",
		"utc_-11": "West eleventh district UTC-11",
		"utc_-12": "West twelfth district UTC-12",
		tracking_time_zone: "Tracking time zone",

		// /2022-04-13
		// /默认标题
		web_title: "Aitrack-Global package tracking",
		web_title_carrier: "Aitrack-Major global carriers",
		web_title_log: "Aitrack-Update notes",
		web_title_user_history: "Aitrack-Historical waybill",
		web_title_automatically: "Aitrack-Login automatically",
		web_title_carrier_detail: "Aitrack-Major global carriers",

		// /2022-04-21
		handle_track_running:
			"Automatic tracking is running, please try again after finished",
		google_login: "Google",
		other_login_title: "Log in with:",

		// /2022-05-07
		web_title_register: "Aitrack-Register an account",
		web_title_found_pwd: "Aitrack-Forgot password",
		web_title_guest_track: "Aitrack-Waybill tracking",
		web_title_user_track: "Aitrack-Waybill inquiry",

		// /2022-05-09
		web_title_description:
			"Aitrack is a global logistics packages tracking platform. Support USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking.",
		web_title_carrier_description:
			"Aitrack is a global logistics packages tracking platform. Support USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking.",
		web_title_log_description:
			"Aitrack has supported USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking, global cooperative carriers keep adding.",
		web_title_user_history_description:
			"Aitrack helps you track your package status anytime, anywhere.",
		web_title_automatically_description:
			"Welcome to Aitrack! We are committed to providing global users with one-stop inquiry services for international logistics packages.",
		web_title_carrier_detail_description:
			"Aitrack helps you track package status.",
		web_title_register_description: "",
		web_title_found_pwd_description: "",
		web_title_guest_track_description: "",
		web_title_user_track_description: "",

		// /2022-06-09
		user_manager_title: "Account management",
		other_type_login_title: "Bind your third party account",
		user_un_bind_title: "Unbound",
		user_bound_title: "Bound",
		user_remove_bind_title: "Unbind",
		user_info_validate_title: "Information verification",
		user_info_phone: "Cellphone",
		user_info_change_phone: "Change cellphone number",
		user_info_bind_phone: "Bind cellphone number",
		user_info_change_email: "Change email address",
		btn_save: "Save",
		send_email_code_more:
			"Please do not frequently request to send verification codes",

		// /2022-06-10
		change_email_old: "Old email verification",
		change_email_new: "New email verification",
		change_email_btn_next: "Next",

		"request_modify-email_106_error":
			"The verification code for previous email address is incorrect",
		"request_modify-email_107_error":
			"The verification code for new email address is incorrect",
		"request_modify-email_same_email_error":
			"The new email address cannot be the same as the previous one",
		bind_phone_title: "verification cellphone",
		un_validate_phone_title: "Unbind",
		phone_is_empty_title: "Cellphone number cannot be empty",
		validate_phone_error: "Incorrect verification code",
		please_send_code_email:
			"Please check your mailbox to get the verification code",
		validate_card: "Verify your email address",

		interface_security_title: "The key to access subscribe interface",
		reset_security_key: "Renew the key",
		security_key: "Key",

		reset_security_confirm:
			"If you renew the key, the previous key will be invalid. Please confirm if you still want to renew it ?",

		btn_search: "Inquiry",
		your_tracking_no: "Your tracking number",

		vip_tracking_page_url: "URL for your own tracking page",
		vip_tracking_page_url_description:
			"Copy the tracking page link and add it to your store's website",
		h5_not_found_title: "Sorry, the tracking number information was not found",
		h5_not_found_content:
			"Please check the tracking number information, unshipped waybill cannot be tracked",

		save_success: "Operation successfully",

		// /2022-06-23
		user_match_rule_title: "Custom event",
		user_match_rule_keywords_title: "Enter event name and search",
		btn_event_management: "Event management",
		// 'btn_new_match_rule': 'Add Custom Event',
		condition_rule_match_type: "Matching rules",
		condition_trajectory_rule: "Standardize activity",

		history_exist_trackingNo_carrierCode:
			"The carrier information of this Waybill already exists and cannot be changed",

		// /2022-06-27
		event_match_page_select_event: "Select event",
		event_match_page_event_manage: "Event management",
		event_match_page_info:
			"(Note: You can skip the status and search events directly)",
		event_match_page_view_all_event: "View all events",
		event_match_page_view_add_event: "Add custom event",
		event_match_page_view_edit_event: "Edit custom event",

		event_match_page_view_state_lv1: "Primary status",
		event_match_page_view_state_lv2: "Secondary status",
		event_match_page_view_event: "Event",

		event_match_page_view_state_lv1_name: "Primary status name",
		event_match_page_view_state_lv2_name: "Secondary status name",
		event_match_page_view_event_name: "Event name",
		event_match_page_view_status_title: "Enabled status",
		event_match_page_view_begin_date_title: "Valid start date",
		event_match_page_view_end_date_title: "Valid end date",

		event_match_page_add_matching_url_title: "Add rule",
		event_match_page_edit_matching_url_title: "Edit rule",
		waybill_status:"Status",
		additional_waybill_status: "Status",
		model_Tips:'Notice',
		tips_content:'The visitors are limited to a daily inquiry. You can login aitrack to check the waybill status and hope you a better experience in Aitrack. ',
		remark: "Remark",
		full_matching_rule: "Fully match",
		reg_matching_rule: "Regex match",
		matching_rule_title: "Matching rules",
		additional_matching_behavior:'extra action',
		overwrite_delivery_status:'overwrite delivered status',
		front_splicing_event_name:'prepend with event name',
		replace_with_event_name:'replace by event name',
		block_deletion:'delete',
		
		matching_rule_error_103: "Update failed",
		matching_rule_error_104: "The matching rule has already existed",
		matching_rule_error_105: "The matching rule is not available",

		error_105: "There was a problem with the service, please try again later",
		error_996: "Search failed, please ask customer service for help. ",

		event_match_page_view_begin_date_empty_title:
			"Please select valid start date",
		event_match_page_view_end_date_empty_title: "Please select valid end date",
		event_match_page_view_begin_end_date_error:
			"Valid end date cannot be earlier than the valid start date",

		event_match_page_test_matching: "Test rule",
		event_match_page_test_matching_title: "Regular expression rules test",
		event_match_page_test_trajectory_title: "Tracking information",
		event_match_page_test_input_trajectory_title:
			"Please enter tracking information",
		event_match_page_test_fail: "Track information doesn't match the rule",

		event_match_help:
			"Learning website: <a href='https://www.runoob.com/regexp/regexp-syntax.html' target='_blank'>https://www.runoob.com/regexp/regexp-syntax.html</a><br/>Simple example:<br/>^ABC matches messages starting with ABC<br/>^ABC$ matches messages ending with ABC<br/>^AB C$ matches messages starting with AB,  ending with C<br/>AB.A0,A30 matches messages starting with AB and ending with any character from 0 to 30<br/>.*AB.* matches messages starting with any character, including AB, and ending with any character <br/>",

		// /2022-07-05
		event_info_save_102: "Event name already exists",

		// /2022-07-06
		carrier_full_name: "Carrier name",
		table_edit: "Edit",
		status: "Status",
		status_open: "Enable",
		status_close: "Disable",

		event_match_page_test_success:
			"Track information matches the rule successfully",

		// /2022-07-07
		user_page_timezone_title: "Time display for tracking event",
		user_page_timezone_item_carrier_timezone: "Display by carrier's time zone",
		user_page_timezone_item_user_timeone: "Display by your time zone",

		page_size: "Page size",
		page_jump: "Jump page",

		// /2022-07-20
		user_bi_analysis_page_title: "Delivery analysis",
		all_carrier_title: "All carriers",
		all_country_title: "All countries",

		day_title: "Day",
		week_title: "Week",
		month_title: "Month",

		day: "day", // 单数
		days: " days", // 复数

		quantile_title: "quantile",
		destination_country_title: "Destination country",
		query_time_range_title: "Time horizon",
		tt_days_title: "Due days",
		ntt_days_title: "Shipping so far",
		temp_kpi_title: "Temporary KPI",

		kpi_manage_title: "Standard KPI",
		otd_analysis_title: "On-Time-Delivery analysis",
		otd_tt_analysis_title: "Delivered",
		otd_utt_analysis_title: "Out of delivery",

		standard_title: "Over the benchmark",
		un_standard_title: "Below the benchmark",
		proper_investment_title: "Delivery status",
		delivery_success_rate_title: "Delivery rate",
		proportion_of_investment_days_title: "Duration proportion",
		details_of_completed_investment_title: "Delivered details",
		failure_rate_title: "Failure rate",
		details_of_incomplete_investment_title: "Details of shipping packages",

		proportion_of_up_to_standard_title: "Benchmark percents",
		time_for_reaching_the_standard_title: "Quantile values",
		kpi_all_title: "All KPI",
		kpi_standard_title: "Standard KPI",
		kpi_temporary_title: "Temporary KPI",

		please_select_query_time_range_title:
			"Please enter the start date and end date.",
		query_time_range_end_less_start_title:
			"The end date you entered occurs before the start date.",
		query_days_15_title: "Query has surpassed the 15-day horizon.",
		query_weeks_15_title: "Query has surpassed the 15-week horizon.",
		query_months_15_title: "Query has surpassed the 15-month horizon.",

		temporary_kpi_rate_0_100_title: "Quantile range of temporary KPI: 0-100",

		unsuccessful_delivery_orders_title: "Number of shipping packages",
		unsuccessful_bid_rate_title: "Failure rate",

		successful_delivery_orders_title: "Number of delivered packages",
		successful_bid_rate_title: "Delivery rate",

		country_title: "Country",
		kpi_type_title: "Type of KPI",
		avg_score_title: "Avg quantile",
		avg_time_title: "Avg duration",
		delay_title: "delay",
		delay_more_9_days_title: "Delay of 10 and more days",

		total_votes_title: "Total",
		delivered_votes_title: "Delivered",
		un_delivered_votes_title: "Undelivered",
		delay_votes_title: "Delayed",
		shipped_orders_title: "Number of delivered packages",
		proportion_title: "Proportion",

		total_number_of_waybills_including_undelivered_waybills:
			"Total(include undelivered)",
		total_title: "Accumulation",
		total_tracking_no: "Total",
		unshipped_orders_title: "Undelivered",

		// /标准KPI管理
		kpi_standard_quantile_title: "Quantile",
		quantile_compliance_days: "KPI(days)",
		dialog_standard_KPI_management_title: "Standard KPI management",
		add_kpi: "Add KPI",
		edit_kpi: "Edit KPI",
		import_103: "Please download the latest template.",

		please_input_kpi_quantile_title: "Please enter the quantile.",
		kpi_quantile_0_100_title: "Quantile range of KPI: 0-100",
		please_select_carrier_title: "Please select a carrier.",
		please_select_quantile_day_title: "Please select a benchmark.",

		select_country_title: "Select country",
		please_select_country_title: "Please select country.",
		kpi_quantile_title: "KPI quantile",
		quantile_day_title: "KPI(days)",
		time_range_title: "Time horizon",

		waybill_collection_time: "Waybill collection time",

		within_title: "Within", // 1天内
		more_than_title: "more than", // 1天以上

		country_origin: "The country of origin",
		destination_country: "The country of destination",
		origin_title: "Origin",
		destination_title: "Destination",

		airline_title: "Airline",
		airline_master_number: "Airline master waybill number",
		tracking_number_information: "The tracking number information",
		view_more_tracking_information: "Show more tracking details",

		no_tracking_data: "No tracking result",

		// /2022-07-27
		please_select_1_item: "Please select at least one",
		recipient_phone: "The recipient phone number",
		recipient_email: "The recipient email",
		export_otd_title: "Export delayed & undelivered waybill list",
		select_export_otd_kpi_type_title: "Select the KPI",
		export_delivered: "Export delivered waybill list",
		export_un_delivered: "Export undelivered waybill list",

		// /2022-08-01
		page_user_title: "Basic setting",
		page_user_member_title: "Member account",
		page_user_subscription_title: "My subscription",
		amount_title: "Account",

		tooltip_title: "Note",
		delete_confirm_title: "Delete ",
		add_member: "Add member",
		edit_member: "Edit",
		setting_pwd: "Password",
		auto_pwd: "Auto create",
		define_pwd: "Setting",
		tooltip_pwd:
			"*After the user is successfully created, the account password is sent to the email address by default, and the password can be changed after logging in",

		ddl_clear_all: "Clear",

		select_tt_days_title: "Choose several due days",
		select_ntt_days_title: "Choose several horizons of shipping so far",
		"8_20_length": "8-20 characters",

		request_portal_115_not_activated_wrong:
			"The account is not activated, please contact the staff",
		kpi_exists_override:
			"This standard KPI already exists, do you want to override it ?",

		recharge: "Top-up",
		subscription: "Spend",
		free: "Free",
		deduction: "Deduct",
		date: "Date",
		quota: "Subscription amount",
		type: "Type",
		quota_validity_period: "Valid date",
		remaining_query_quota: "Balance",
		please_choose: "Please select ",
		please_input: "Please enter ",
		not_empty: " is required",
		email: "Email",

		clear: "Clear",

		save_successful: "Saved",
		delete_successful: "Deleted",

		// /2022-08-11
		user_notify_center_title: "Notification center",
		page_user_notify_task_title: "Notification task",
		page_user_notify_template_title: "Notification template",
		add: "Add",

		task_name: "Task",
		trigger_task_event: "Trigger event",
		notify_type: "Channel",
		mail: "Email",
		sms: "SMS",
		notify_event_type: "Trigger event level",
		update_time: "Modification date",
		add_task: "Add task",
		edit_task: "Edit task",

		task_tooltip: "Attention: All sent as default.",
		template: "Template",
		preview: "Preview",
		template_name: "Template name",

		email_template: "Email template",
		add_email_template: "Add email template",
		edit_email_template: "Edit email template",

		sms_template: "SMS template",
		add_sms_template: "Add SMS template",
		edit_sms_template: "Edit SMS template",

		template_desc: "Template description",

		view_url: "Click here to check more waybill details",

		template_content: "Content",

		view: "View",
		edit: "Edit",

		// 2022-08-15
		send_test_sms: "Send test SMS",
		send_test_email: "Send test email",
		send_test_sms_target: "Send test SMS to",
		send_test_email_target: "Send test email to",

		send_test_sms_tooltip:
			"Test SMS that you sent in success will expend your subscription.",
		send_test_email_tooltip:
			"Test email that you sent in success will expend your subscription.",
		send_successful: "Send successfully",

		view_sms_template: "View test SMS",
		view_email_template: "View test email",

		email_subject: "Theme content",

		api_trackings_109:
			"Sorry to tell you that the waybill tracking service is invalid since your subscription has been used up.",
		api_trackings_110:
			"Sorry to tell you that the waybill tracking service is invalid since your subscription has been expired.",

		recommend: "Recommend",
		customize: "Customize",

		exists_template_name: "Duplicated template name",
		exists_task_name: "Duplicated task name",
		join_task_template: "Fail to delete. The template is in use",

		// /2022-08-18
		template_language: "Template language",
		EN: "EN",
		CN: "CN",
		ALL: "ALL",

		user_page_callback_url: "Subscribe callback service", // 订阅回调
		user_page_callback_url_tip:
			"Specify the callback url as you have add callback service. AiTrack will be invoked to pushing the latest tracking data through the API",
		callback_url: "Callback url",
		callback_url_error: "The format of callback url is incorrect.",

		balance_detail: "Stored value",
		subscribe_detail: "Subscription balance",
		balance_yuan: "Stored value",
		operate_time: "Operate time",
		balance_recharge: "Top-up",
		money_yuan: "Expense",

		sms_pay: "SMS consumption",
		change_add: "Increment",
		change_reduce: "Reduction",

		// /2022-08-25
		page_sms_service_title: "SMS service",
		page_sms_service_description:
			"It can provide you with customized SMS notifications, notify recipients to pick up or handle exceptions, etc., to quickly improve the quality of customer service",
		page_sms_service_note:
			"Reminder: Price of SMS may change as supplier varies price without any extra notification .",
		telecom_operators: "Telecommunications suppliers",
		sms_price: "Price (CNY/Message)",
		sms_price_download: "Download the SMS pricing sheet",
		sms_template_note:
			"Reminder: Please pay attention to the content of the SMS template. If the template content is too long, multiple charges may be charged. English letters and numbers are limited to 160 characters per SMS, and the content of SMS containing non-English letters or special characters will be downgraded to 70 characters per SMS. After the limit is exceeded, the content can be sent normally, but an additional SMS fee will be charged. For specific charges, please refer to the SMS sending record page.",
		page_shop_title: "My shops",
		page_shop_tooltip: "Up to 10 shops can be added",
		add_shop: "Add",
		edit_shop: "Edit",
		shop_name: "Shop name",
		shop_type: "Shop type",
		shop_url: "Shop address",
		shop_status: "Shop status",
		shop_delete_title:
			"After deletion, all the waybills in the shop will be cleared and cannot be recovered. Are you sure you want to delete the shop?",
		shop_disable:
			"After deactivation, you will not be able to add a waybill, and the existing waybill will no longer be synchronized with the track. Are you sure you want to deactivate?",
		import_data: "Import",
		synchronous_data: "Sync",
		synchronous_days_range: "Time horizon of imported data",
		shop_save_104: "Parameter is invalid",
		shop_save_105: "The number of shop exceeds the limit",
		shop_save_106: "Sync time exceeds limit",
		shop_save_107: "Shop name is repeated",
		synchronous_data_tooltip:
			"The waybill will be imported within the time range, and the new waybill will be automatically and continuously synchronized. The first synchronization may last from a few minutes to a few days.",
		page_user_sms_detail_title: "SMS expense detail",
		send_time: "Sending time",
		send_status: "Sending status",
		successful: "Succeed",
		failure: "Failed",
		send_country: "Country",
		received_phone: "Phone",
		count_sms: "Quantity of billed SMS",
		money_type: "Currency",
		fee: "Expense",
		with_tracking_no: "Related order number",
		trigger_event_name: "Trigger event",
		between_30_days: "Within 30 days",

		synchronous_now: "Sync",
		api_trackings_108: "Importing data is not allowed for deactivated shop.",
		shop_sync_108: "Data synchronization is not allowed for deactivated shop.",

		page_api_title: "API configuration",
		add_api: "Add API",
		edit_api: "Edit API",
		api_nick_name: "API alias",
		connect_special_api: "Connect to dedicated API",
		URL: "URL",
		language: "Language",
		front_btn: "Previous",
		api_save_105: "The API alias already exists.",
		api_save_106: "The API configuration already exists.",

		enable_url: "Valid URL",
		disable_url: "Invalid URL",
		invalid_query: "Invalid query",
		subscribeBalance_0:
			"Your subscription has been used up, please contact customer service to recharge.",
		insufficient_subscribeBalance: "Insufficient quota",

		api_trackings_101: "Please enter at least one tracking number",
		api_trackings_102: "Too many waybills",
		api_trackings_108_2: "Quota used up",
		wait_query: "Pending",
		money: "CNY",
		api_event_info_delete_105:
			"Delete failed: We couldn't delete the event in use. Please check it in custom event.",
		api_event_info_delete_108:
			"Delete failed: We couldn't delete the event in use. Please check it in notification task.",

		menu_app: "Featured services",
		menu_more: "Explore more",

		cancel_sync: "Stop syncing",
		sync_status_tooltip:
			"You can view the tracking progress in the history follow waybill.",
		sync_status_step1: "Synced",
		sync_status_step2: "15 of them already exist and are covered",
		sync_status_title: "Syncing.",
		sync_status_title_pending: "Sync pending.",
		sync_status_title_stopping: "Stop syncing.",
		sync_status_title_stopped: "Sync has stopped.",
		sync_status_title_finished: "Syncing finished.",
		sync_status_ok: "Understood",
		sync_cancel_title:
			"Syncing work stop so that the waybills will no longer be synchronized. Are you sure to stop syncing ?",
		default_shop: "Default shop",
		shop: "Shop",
		history_more_infomation: "More",
		history_more_infomation_open: "Unfold",
		history_more_infomation_close: "Collapse",
		carrier_website: "Official website",
		range_user_bi_analysis_page_title: "Segment distribution analysis",
		data_analysis: "Data analysis",
		about_us: "About us",
		about_us_des:
			"Established in 2018, AI TRACK provides accurate all-in-one package logistics and tracking services for over 500 famous eCommerce and express companies all over the world, including USPS, DHL, UPS, and FedEx. ",
		about_us_des2:
			" As an indispensable package tracking system for the eCommerce and international logistics industry, AI TRACK has had a great impact on millions of online retailers and their customers, and quickly became the world leading third party package tracking platform, with daily tracking of more than 500,000 packages and over 80,000 registered users.",
		our_mission: "Our Mission",
		our_mission_des:
			"We strive to make after-sales the best part of the consumer’s shopping experience. Satisfying customers’ needs is the main goal for business success, and after-sales services determine whether the customer will be a one-time buyer or a happy returning customer. We help eCommerce businesses treat customers the way they have always wanted to.",
		about_us_title: "Global All-In-One Package Tracking",
		// /2022-11-22
		range_start_status: "Start status",
		range_end_status: "End status",

		// /2022-11-25
		online_api: "Online API",
		offline_api: "Offline API",
		api_type: "Type of API",
		zy_online_api: "Dedicated online API",
		zy_offline_api: "Dedicated offline API",
		path: "Route",
		trajectory_time: "Activity time",
		trajectory_desc: "Activity",

		// /2022-11-29
		api_login_name: "Account",
		api_login_pwd: "Password",
		api_ftp_type: "Protocol",
		tracking: "Track",
		buy_subscribe_balance: "Purchase",
		subscribe_shop_title: "Plan",
		subscribe_shop_balance: "Stored value",
		subscribe_shop_times: "Balance",
		subscribe_shop_buy: "Purchase by stored value",
		subscribe_shop_desc:
			"After purchase, the validity period of all subscription credits will be updated to the latest validity period.",
		subscribe_shop_row_subscribe: " Credits",
		subscribe_shop_row_item: " Orders",
		subscribe_shop_row_pic: "Per order",
		subscribe_shop_row_start_date: "From date of purchase",
		"1month": "1 month",
		"3month": "3 months",
		"6month": "0.5 year",
		"1year": "1 year",
		"2year": "2 years",
		"5year": "5 years",
		balance_order_productName: "Product",
		balance_order_productPrice: "Price",
		balance_order_productCount: "Amount",
		balance_order_productTotal: "Sum",
		balance_order_productPayType: "Payment method",
		balance_order_balancePay: "Pay by stored value",
		balance_order_balance: "Stored value",
		balance_order_needPay: "Payables",
		balance_order_pay: "Pay",
		user_subscribe_payment_103: "Insufficient balance, please recharge",
		user_subscribe_payment_104: "Payment failed, please try again",
		user_subscribe_payment_success: "Plan purchased successfully.",
		cost_by_subscribe: "Subscription consumption",
		buy_subscribe: "Plan",
		valid: "Valid for ",
		buy_confirm: "Confirm the purchase of this product ?",
		sms_country_price_add: "Add country",
		sms_country_price_tooltip:
			"Note: SMS will be only sent to selected country.",
		choose_send_country: "Select a country",
		yuan_piece: "CNY/message",
		exist_send_country: "The country has been selected.",
		please_choose_send_country: "Add a country",
		plan_title: "Subscription plan",
		sms_description_part1_1: "International Package Tracking",
		sms_description_part1_2: "Accurate, Reliable, Up-to-date",
		sms_description_part1_3:
			"Access the tracking data of 500+ carriers worldwide via API integration, optimizing your operation.",
		sms_description_part1_4: "Start free",
		sms_description_part2_1: "One Solution,Various Use Cases",
		sms_description_part2_2: "Track and trace your shipments in bulk.",
		sms_description_part2_3: "Build a tracking portal in your own system.",
		sms_description_part2_4:
			"Avoid high cost due to multi-carrier integration.",
		sms_description_part3_1: "AITRACK can process massive trackings",
		sms_description_part3_2:
			"It automatically feeds your endpoint with the latest and historical tracking data covering the entire life cycle.",
		sms_description_part4_1:
			"Data Operation Granularized: Forcast, Monitor, Intervene",
		sms_description_part4_2: "Tracking data that counts",
		sms_description_part4_3:
			"The combination of 8 status makes it no-brainer to understand the shipping progress, and the cause of delivery exceptions.",
		sms_description_part4_4: "The worldwide carriers coverd.",
		sms_description_part4_5:
			"AITRACK now includes 500+ mainstream carriers worldwide, with ever-increasing new ones every week. Contact us anytime and request an unsupported carrier.",
		sms_description_part4_6: "Logistics visibility automized",
		sms_description_part4_7:
			"The system auto-identifies over 80% of the carriers with the tracking number. All trackings are synced from the carrier on a regular basis, non-stop until fulfilled.",
		sms_description_part5_1: "What's the Plan",
		sms_description_part5_2: "Each plan has a valid period of 6 months.",
		sms_description_part5_3: "Some details:",
		sms_description_part5_4_1:
			"· Both API tracking and portal page searching deduct the subscription amount.",
		sms_description_part5_4_2:
			"· One package can track many times through the same carrier and the same language and it only bills once.  If the package was deleted in the list, you have to pay it one more.",
		sms_description_part5_4_3:
			"· Repeated calls to the API for the same waybill will not be billed repeatedly.",
		sms_description_part5_4_4:
			"· 5,000 subscriptions for free will be gaven to new users.",
		sms_description_part5_4_5:
			"· The expiry date of the subscription will be extend to the date by the last top-up.",
		free_gift_subscribe: "Free for new users ",
		free: "Free",
		shopify_oauth: "Authorize now",
		shopify_reset_oauth: "Reauthorize",
		shopify_success: "Authorize successfully",
		oauth_status: "Authorization status",
		oauth_status_Unauthorized: "Unauthorized",
		oauth_status_Authorized: "Authorized",
		oauth_status_AuthExpired: "Expired authorization",

		//2022-11-04
		shopify_title:
			"The Aitrack account connecting to Shopify  will be created automatically",
		shopify_description:
			"The password has been sent to your email # and you can change or reset it after signing in to your account",

		page_user_customer_title: "Basic configuration",
		customer_id: "Customer ID",

		table_header_sort: "Sorting",
		matchRule_sort_title: "Sorting(Positive Integer)",
		matchRule_sort_tooltip:
			"If the tracking description matches multiple formulas, it will be matched according to the sorting of the formulas. <br/>Sorting number: positive integers (such as: 1,2,3,5,88, etc.). <br/>Sorting priority: The smaller the value, the higher the matching priority. If the sorting is not filled in,the priority is set to the lowest in default except for the bottom formula. The bottom formula have a lower priority than common formulas.",
		test: "Test",
		match_success: "Matched",
		match_fail: "Match failed",
		match_track: "Regex test",
		match_trackingNo: "Tracking number test",
		close: "Close",
		tracking_description: "Description",
		is_self: "Customized",
		is_bottom_formula: "Bottom formula",
		yes: "Y",
		no: "N",
		place: "Location",
		other_match_reg: "Alternative regex",
		no_balance:
			"Your <b>subscription quota</b> is insufficient, please click 'Purchase' to place an order.",
		expiry_balance:
			"Your subscription is invalid.(<b>Beyond expiration</b>). The validity period can be extended after purchasing the quota.",

		range_otd_tt_analysis_title: "Segment completed",
		range_otd_utt_analysis_title: "Segment unfinished",
		range_tt_days_title: "Due days",
		range_ntt_days_title: "Shipping so far",
		range_proper_investment_title: "Segment status",
		range_delivery_success_rate_title: "Segment completed rate",
		range_proportion_of_investment_days_title: "Duration proportion",
		range_details_of_completed_investment_title: "Segment completed details",
		range_failure_rate_title: "Unfinished rate",
		range_details_of_incomplete_investment_title: "Unfinished details",
		range_unsuccessful_delivery_orders_title: "Shipping",
		range_unsuccessful_bid_rate_title: "Unfinished rate",
		range_successful_delivery_orders_title: "Completed",
		range_successful_bid_rate_title: "Segment completed rate",
		range_shipped_orders_title: "Completed",
		range_unshipped_orders_title: "Shipping",
		range_export_delivered: "Export the completed waybills",
		range_export_un_delivered: "Export the unfinished waybills",
		range_select_tt_days_title: "Choose several due days",
		range_select_ntt_days_title: "Choose several horizons of shipping so far",
		range_delivered_votes_title: "Completed",
		range_un_delivered_votes_title: "Unfinished",
		range_total_number_of_waybills_including_undelivered_waybills: "Total",
		range_export_otd_title: "Export delayed & unfinished waybills",
		range_standard_title: "Completed",
		range_un_standard_title: "Unfinished",
		range_proportion_of_up_to_standard_title: "Completion",
		range_time_for_reaching_the_standard_title: "Completion period",

		event_status: "Event",
		event_status_same: "Events cannot be the same",
		bind_title: "Bind",
		google_bind_success: "You have successfully bound your account.",
		google_unbind_success: "You have successfully unbound your account.",
		google_unbind_tip:
			"If you unbind account to the site, you will not be able to login with one click. Instead, your AITrack site account is still accessible. Are you sure to unbind ?",
		google_bind_exists:
			"This Google account has been bound to another AITrack account, please unbind the anterior and then rebind.",

		//2023-06-14
		user_page_black_event_title: "Prohibited event",
		user_page_black_event_add: "Add prohibited event",
		user_page_black_event_column_name: "Custom event",

		//2023-07-31
		page_carrier_config_title: "服务商配置",
		page_carrier_config_tooltip:
			"可选择同步追踪或异步追踪，<br/>1. 同步追踪可以手动批量强制追踪.<br/>2. 异步追踪无需手动更新, 运单自动更新轨迹.<br/>3. 异步追踪轨迹更新时效更高, 更新更及时.",
		trackType: "追踪方式",
		config_api_track: "配置API追踪",
		track_sync: "同步追踪",
		track_async: "异步追踪",

		//2023-08-03
		event_match_page_view_event_code: "Event code",
		event_info_save_106: "Event code already exists",
	},
};
