const cache = {
    save: (key, data) => {
        window.localStorage.setItem(key, data);
    },
    getByKey: (key) => {
        return window.localStorage.getItem(key);
    },
    deleteByKey: (key) => {
        window.localStorage.removeItem(key);
    },

    saveEmail: (data) => {
        cache.save("email", data);
    },
    getEmail: () => {
        return cache.getByKey("email");
    },
    logout: () => {
        cache.deleteByKey("userName");
    },

    savePwd: (data) => {
        cache.save("pwd", data);
    },
    getPwd: () => {
        return cache.getByKey("pwd");
    },

    saveUserName: (data) => {
        cache.save("userName", data);
    },
    getUserName: () => {
        return cache.getByKey("userName");
    },

    saveRemember: (data) => {
        cache.save("remember", data);
    },
    getRemember: () => {
        return cache.getByKey("remember");
    },

    saveLang: (data) => {
        cache.save("lang", data);
    },
    getLang: () => {
        return cache.getByKey("lang") || 'en';
    },

    saveTaskId: (data) => {
        cache.save("taskId", data);
    },
    getTaskId: () => {
        return cache.getByKey("taskId");
    },

    saveSuperAdmin: (data) => {
        cache.save("superAdmin", data);
    },
    getSuperAdmin: () => {
        return cache.getByKey("superAdmin");
    },

    
}
export default cache
